<template>
  <v-dialog v-model="showModal" scrollable max-width="600px" @click:outside="handleClose">
    <v-card>
      <v-data-table
        ref="table"
        :headers="headers"
        :items="cancelledBoxes"
        item-key="box_no"
        items-per-page="-1"
        checkbox-color="primary"
        fixed-header
        dense
        show-select
        hide-default-footer
        disable-pagination
        @input="selectedBoxes"
      ></v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" :loading="loading" :disabled="disabled" @click="handleConfirm">
          {{ label.BTN_UNDO_BOX_CANCEL }}
        </v-btn>
        <v-btn color="warning" text :disabled="loading" @click="handleClose">
          {{ label.BTN_CANCEL }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    showModal: { type: Boolean, default: false },
    cancelledBoxes: Array,
  },
  emits: ['click:confirm', 'click:close'],
  computed: {
    ...mapGetters({
      label: 'defined/labels',
    }),
  },
  data() {
    return {
      headers: [
        {
          text: 'BoxNo',
          sortable: false,
          value: 'box_no',
        },
      ],
      cancelUndoBoxes: [],
    };
  },
  methods: {
    handleConfirm() {
      this.$emit('click:confirm', this.cancelUndoBoxes);
      this.handleClose();
    },
    handleClose() {
      this.$emit('click:close');
    },
    selectedBoxes(box) {
      this.cancelUndoBoxes = box.map(({ box_no }) => box_no);
    },
  },
};
</script>
<style scoped>
.theme--light.v-data-table
  :deep()
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(#stop_hover):not(
    .text-decoration-line-through
  ):not(.not-change-hover-color) {
  background: #8acbffb5 !important;
}

.theme--dark.v-data-table :deep() tbody tr#stop_hover:hover,
.theme--dark.v-data-table :deep() tbody tr.text-decoration-line-through:hover {
  background: transparent !important;
}
.theme--dark.v-data-table
  :deep()
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(#stop_hover):not(
    .text-decoration-line-through
  ):not(.not-change-hover-color) {
  background: #045ba2d6 !important;
}
.theme--light.v-data-table--fixed-header :deep() thead th {
  background: rgb(224, 224, 224) !important;
  white-space: pre;
  top: 0;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
}
.theme--dark.v-data-table--fixed-header :deep() thead th {
  background: rgba(0, 43, 91, 1) !important;
  white-space: pre;
  top: 0;
  box-shadow: inset 0 -1px 0 rgb(255 255 255 / 12%);
}
</style>
